﻿import {DataService, CrudException} from "bootstrap-aunoa";
import {computed, ref, Ref, unref} from "vue";

const missingCrudService = (resolve: any, reject: any) => reject(<CrudException>{
    message: "CRUD-Service does not exist."
});
const notImplemented = (resolve: any, reject: any, func: string) => reject(<CrudException>{
    message: `Not implemented '${func}' in CRUD-Service`
});

const notImplementedEmpty = (resolve: any, reject: any) => notImplemented(resolve, reject, "empty");
const notImplementedAdjust = (resolve: any, reject: any) => notImplemented(resolve, reject, "adjust");
const notImplementedCreate = (resolve: any, reject: any) => notImplemented(resolve, reject, "create");
const notImplementedRead = (resolve: any, reject: any) => notImplemented(resolve, reject, "read");
const notImplementedReadList = (resolve: any, reject: any) => notImplemented(resolve, reject, "readList");
const notImplementedUpdate = (resolve: any, reject: any) => notImplemented(resolve, reject, "update");
const notImplementedDelete = (resolve: any, reject: any) => notImplemented(resolve, reject, "delete");
const notImplementedReadDetails = (resolve: any, reject: any) => notImplemented(resolve, reject, "readDetails");
const notImplementedBulkCreate = (resolve: any, reject: any) => notImplemented(resolve, reject, "bulkCreate");

const tool = <TEntity = any, TKey = any, TQuery = any>(s: DataService<TEntity, TKey, TQuery> | Ref<DataService<TEntity, TKey, TQuery>>) => {
    const service = unref(s);

    return {
        empty: (query: TQuery): Promise<TEntity> =>
            service ?
                service.empty
                    ? service.empty(query)
                    : new Promise<TEntity>(notImplementedEmpty)
                : new Promise<TEntity>(missingCrudService),

        adjust: (query: TQuery, entity: TEntity): Promise<TEntity> =>
            service ?
                service.adjust
                    ? service.adjust(query, entity).catch(e => {
                        switch (e.httpStatus) {
                            case 304:
                                return entity;
                            default:
                                throw e;
                        }
                    })
                    : new Promise<TEntity>(notImplementedAdjust)
                : new Promise<TEntity>(missingCrudService),

        create: (entity: TEntity): Promise<TEntity> =>
            service ?
                service.create
                    ? service.create(entity)
                    : new Promise<TEntity>(notImplementedCreate)
                : new Promise<TEntity>(missingCrudService),

        read: (key: TKey, etag?: string, entity?: TEntity): Promise<TEntity> =>
            service ?
                service.read
                    ? service.read(key, etag, entity)
                    : new Promise<TEntity>(notImplementedRead)
                : new Promise<TEntity>(missingCrudService),
        
        readList: (query: TQuery, skip: number, take: number): Promise<TEntity[]> =>
            service ?
                service.readList
                    ? service.readList(query, {skip, take})
                    : new Promise<TEntity[]>(notImplementedReadList)
                : new Promise<TEntity[]>(missingCrudService),
        
        update: (key: TKey, entity: TEntity, etag?: string): Promise<TEntity> =>
            service ?
                service.update
                    ? service.update(key, entity, etag)
                    : new Promise<TEntity>(notImplementedUpdate)
                : new Promise<TEntity>(missingCrudService),
        
        delete: (key: TKey, etag?: string): Promise<void> =>
            service ?
                service.delete
                    ? service.delete(key, etag)
                    : new Promise(notImplementedDelete)
                : new Promise(missingCrudService),
        
        readDetails: (key: TKey, entity: TEntity, etag?: string): Promise<TEntity> =>
            service ?
                service.readDetails
                    ? service.readDetails(key, entity, etag)
                    : new Promise<TEntity>(notImplementedReadDetails)
                : new Promise<TEntity>(missingCrudService),
        
        bulkCreate: (obj: any, template?: string): Promise<any> =>
            service ?
                service.bulkCreate
                    ? service.bulkCreate(obj, template)
                    : new Promise<any>(notImplementedBulkCreate)
                : new Promise<any>(missingCrudService)
        
    }

}


export const useDataService = <TEntity = any, TKey = any, TQuery = any>(service: DataService<TEntity, TKey, TQuery> | Ref<DataService<TEntity, TKey, TQuery>>) => {

    const getKeySupported = computed<boolean>(() => !!unref(service)?.key);
    const getETagSupported = computed<boolean>(() => !!unref(service)?.etag);
    const getDisabledSupported = computed<boolean>(() => !!unref(service)?.disabled);
    const createSupported = computed<boolean>(() => !!unref(service)?.create);
    const readSupported = computed<boolean>(() => !!unref(service)?.read && getKeySupported.value);
    const readListSupported = computed<boolean>(() => !!unref(service)?.readList);
    const updateSupported = computed<boolean>(() => !!unref(service)?.update && getKeySupported.value);
    const deleteSupported = computed<boolean>(() => !!unref(service)?.delete && getKeySupported.value);
    const adjustSupported = computed<boolean>(() => !!unref(service)?.adjust);
    const emptySupported = computed<boolean>(() => !!unref(service)?.empty);
    const readDetailsSupported = computed<boolean>(() => !!unref(service)?.readDetails && getKeySupported.value);
    const bulkCreateSupported = computed<any>(() => !!unref(service)?.bulkCreate);

    // ******************************

    return {
        getKeySupported,
        getETagSupported,
        getDisabledSupported,
        createSupported,
        readSupported,
        readListSupported,
        updateSupported,
        deleteSupported,
        adjustSupported,
        emptySupported,
        readDetailsSupported,
        bulkCreateSupported,

        getKey: (entity: TEntity) => unref(service)?.key?.(entity),
        getETag: (entity: TEntity) => unref(service)?.etag?.(entity),
        isDisabled: (entity: TEntity) => unref(service)?.disabled?.(entity),

        empty: (query: TQuery) => tool(service).empty(query),
        adjust: (query: TQuery, entity: TEntity) => tool(service).adjust(query, entity),
        create: (entity: TEntity) => tool(service).create(entity),
        read: (key: TKey, etag?: string, entity?: TEntity) => tool(service).read(key, etag, entity),
        readList: (query: TQuery, skip: number, take: number) => tool(service).readList(query, skip, take),
        update: (key: TKey, entity: TEntity, etag?: string) => tool(service).update(key, entity, etag),
        delete: (key: TKey, etag?: string) => tool(service).delete(key, etag),
        readDetails: (key: TKey, entity: TEntity, etag?: string) => tool(service).readDetails(key, entity, etag),
        bulkCreate: (obj: any, template?: string) => tool(service).bulkCreate(obj, template)
    }

};