import type {Ref, InjectionKey} from "vue";
import type {PromisableEvent, BusyOptions} from "../types";

import {hasMessage, isDefined, isError, isString} from "./inspect";
import {useAunoaOptions} from "./useAunoaOptions";
import {useAunoaI18n} from "./useAunoaI18n";
import {ref, provide, inject} from "vue"
import {useRouter} from "vue-router";

export type MessageType = "info" | "success" | "warning" | "error" | "system-error";

export interface Action {
    icon?: string;
    text?: string;

    onClick(event: PromisableEvent): void;
}

export interface ErrorMessage {
    message: string;
    icon?: string;
    httpStatus?: number;
    httpStatusText?: string;
}

export interface Message {
    content?: string | ErrorMessage;
    icon?: string;
    title?: string;
    topic?: string;
    group?: string | Symbol;
    duration?: number;
    actions?: Action[];
}

export interface TypedMessage extends Message {
    type: MessageType;
    date: number;
    monospace: boolean;
    duration: number;
    content: string;
    actions?: Action[];
}

const INJECTION_KEY: InjectionKey<Ref<TypedMessage[]>> = Symbol("AUNOA_TOAST_MESSAGES");

const durationDict: Record<MessageType, number> = {
    "info": 5000,
    "success": 5000,
    "warning": 10000,
    "error": 15000,
    "system-error": 0
}

const getDuration = (type: MessageType, message: Message): number =>
    message.actions && message.actions.length > 0
        ? 0
        : message.duration ?? durationDict[type]


export const provideToasts = () => {

    const messages = ref(<TypedMessage[]>[]);

    provide(INJECTION_KEY, messages);

    const clean = () =>
        messages.value
            .map((m, i) => m.type !== "system-error" ? i : -1)
            .reverse()
            .forEach(i => i >= 0 && messages.value.splice(i, 1));

    return {
        messages,
        clean
    }
}


export const useToasts = () => {

    const {options: aunoaOptions} = useAunoaOptions();
    const router = useRouter();

    const {t, ensureTextTranslated} = useAunoaI18n();

    const messages = inject(INJECTION_KEY, ref([]));

    const removeFromGroup = (group: string | Symbol) =>
        messages.value
            .map((m, i) => m.group === group ? i : -1)
            .reverse()
            .forEach(i => i >= 0 && messages.value.splice(i, 1))

    const remove = (message: TypedMessage) => {
        const index = messages.value.indexOf(message);
        if (index > -1) {
            messages.value.splice(index, 1);
        }
    };

    const add = (type: MessageType, message: any) => {
        if (isString(message) || hasMessage(message)) {
            message = {content: message};
        }
        messages.value
            .map((m, i) => m.type !== "system-error" ? i : -1)
            .reverse()
            .forEach(i => i >= 0 && messages.value.splice(i, 1));

        const monospace = isError(message.content) && message.content.$$monospace;
        const defaultActions: Action[] = [];

        if (hasMessage(message.content)) {
            const httpStatus = message.content.httpStatus;
            message.title = message.content.subject;
            message.icon = message.content.icon;
            message.content = message.content.message;
            switch (httpStatus) {
                case 401:
                    defaultActions.push({
                        text: "@@Aunoa.Auth.LoginCommand",
                        onClick: (event: PromisableEvent) =>
                            event.setPromise(router.push(<any>aunoaOptions.auth.logoutRoute))
                    })
                    break;
                case 410:
                    // todo: translate known errors
                    //message.content =  ensureTextTranslated("@@Exceptions.EntityAlreadyDeleted");
                    //httpStatus
                    break;
            }
        }

        if (type === "system-error") {
            console.error(message.content, `[topic: ${message.topic || 'none'}]`, `[title: ${message.title || 'none'}]`);
        }

        const date = Date.now();
        const duration = getDuration(type, message);
        const typedMessage = {
            ...message,
            actions: message.action || defaultActions,
            type,
            date,
            duration,
            monospace
        } as TypedMessage;

        typedMessage.actions?.forEach(a => {
            const onClick = a.onClick;
            a.onClick = (event: PromisableEvent) => {
                const setPromise = event.setPromise;
                event.setPromise = (promise: Promise<any>, options?: BusyOptions) => {
                    options = options || {};
                    options.toastGroup = isDefined(options.toastGroup) ? options.toastGroup : typedMessage.group;

                    const busyPromise = setPromise(promise, options);
                    busyPromise
                        .catch()
                        .finally(() => remove(typedMessage));
                    return busyPromise;
                }
                onClick(event);
            }
        });

        messages.value.push(typedMessage);
        if (typedMessage.group) {
            setTimeout(() =>
                messages.value
                    .map((m, i) => m.date !== typedMessage.date && m.group === typedMessage.group ? i : -1)
                    .reverse()
                    .forEach(i => i >= 0 && messages.value.splice(i, 1)), 500);
        }

        if (duration) {
            setTimeout(() => remove(typedMessage), duration);
        }
        return typedMessage;
    };

    const addToast = (type: MessageType, message: Message | string | ErrorMessage = "") =>
        add(type, message);

    const addInfoToast = (message: Message | string | ErrorMessage = "") =>
        add("info", message);

    const addSuccessToast = (message: Message | string | ErrorMessage = "") =>
        add("success", message);

    const addWarningToast = (message: Message | string | ErrorMessage = "") =>
        add("warning", message);

    const addErrorToast = (message: Message | string | ErrorMessage = "") =>
        add("error", message);

    const addSystemErrorToast = (message: Message | string | ErrorMessage = "") =>
        add("system-error", message);

    return {
        addToast,
        addInfoToast,
        addSuccessToast,
        addWarningToast,
        addErrorToast,
        addSystemErrorToast,
        removeFromGroup,
        remove
    }

}