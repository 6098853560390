﻿import type {LookupFactory} from "./lookup/useLookup";
import type {Forms, Conditions, LookupDisplayOptions, LookupValueOptions} from "../types";

import {isBoolean, isFunction, isNumber, isObject, isString, isUndefined} from "../utils/inspect";
import {useLookupValueOptions, useLookupDisplayOptions} from "./lookup/useLookupOptions";
import {createConditionsModel} from "./conditionsModel";
import {getPropertyName} from "../utils/reflectionLike";
import {buildArrayProperty} from "../utils/properties";
import {isLookupFactory} from "./lookup/useLookup";


interface FieldsHelper {
    createModel(): Forms.Model.Field;
}

interface RowsAndFieldsHelper {
    createModel(): Forms.Model.Field | Forms.Model.Field[];
}

interface GroupHelper {
    name(): string;

    createModel(): Forms.Model.Group;
}

export interface FormModelOptions {
}

type CreateFunc<TC> = (model: string) => {
    builder: TC,
    createModel: () => Forms.Model.Field
}

export const mergeFormModels = (...forms: (Forms.Model.Form)[]) => {
    forms = forms.filter(Boolean);
    if (forms.length == 1) {
        return forms[0];
    }
    const mergedForm: Forms.Model.Form = {};
    forms.forEach(form => {
        Object.keys(form).forEach(groupName => {
            const sourceGroup = form[groupName];
            const targetGroup = mergedForm[groupName] = mergedForm[groupName] || {
                component: sourceGroup.component,
                label: sourceGroup.label,
                fields: [],
            };
            sourceGroup.fields.forEach(fieldOrFields => {
                if (Array.isArray(fieldOrFields)) {
                    if (fieldOrFields.length == 1) {
                        targetGroup.fields.push(fieldOrFields[0]);
                    } else {
                        targetGroup.fields.push(fieldOrFields);
                    }
                } else {
                    targetGroup.fields.push(fieldOrFields);
                }
            });
        })
    })

    return mergedForm
}

const textBox = (property: string) => {
    let component: Forms.Model.FieldComponent = "AunoaTextBox";

    let _placeholder: string;
    let _disabled: boolean;
    let _readonly: boolean;
    let _required: boolean;
    let _forPassword: boolean;
    let _forID: boolean;
    let _minLength: number;
    let _maxLength: number;
    let _lines: number;
    let _textCasing: string | undefined;

    const builder: Forms.Builder.TextBox = {
        //Component: (value: Forms.Model.FieldComponent) => set(() => _component = value),
        Placeholder: (value: string) => set(() => _placeholder = value),
        Required: (value = true) => set(() => _required = value),
        Readonly: (value = true) => set(() => _readonly = value),
        Disabled: (value = true) => set(() => _disabled = value),
        ForPassword: (value = true) => set(() => _forPassword = value),
        ForID: (value = true) => set(() => _forID = value),
        Length: (value: number) => builder.MinLength(value).MaxLength(value),
        MinLength: (value: number) => set(() => _minLength = value),
        MaxLength: (value: number) => set(() => _maxLength = value),
        MultiLine: (value: number) => set(() => _lines = value),
        UpperCase: (value = true) => set(() => _textCasing = value ? "upper" : undefined),
        LowerCase: (value = true) => set(() => _textCasing = value ? "lower" : undefined)
    }

    function set(action: () => void) {
        action();
        return builder;
    }

    return {
        builder,
        createModel: (): Forms.Model.Field => ({
            property,
            component,
            attrs: {
                "type": _forPassword ? "password" : undefined,
                "placeholder": _placeholder || undefined,
                "disabled": _disabled || undefined,
                "readonly": _readonly || undefined,
                "lines": _lines > 1 ? _lines : undefined,
                "text-casing": _textCasing
            },
            rules: [
                _required ? "required" : undefined,
                _forID ? "id" : undefined,
                isNumber(_minLength) ? `minLength:${_minLength}` : undefined,
                isNumber(_maxLength) ? `maxLength:${_maxLength}` : undefined
            ].filter(Boolean).join("|") || undefined
        })
    };
};

const numberBox = (property: string) => {
    let component: Forms.Model.FieldComponent = "AunoaNumberBox";

    let _placeholder: string;
    let _disabled: boolean;
    let _readonly: boolean;
    let _required: boolean;
    let _nullable: boolean;
    let _isInteger: boolean;
    let _isDecimal: boolean;
    let _min: number;
    let _max: number;

    const builder: Forms.Builder.NumberBox = {
        Placeholder: (value: string) => set(() => _placeholder = value),
        Required: (value = true) => set(() => _required = value),
        Readonly: (value = true) => set(() => _readonly = value),
        Disabled: (value = true) => set(() => _disabled = value),
        Nullable: (value = true) => set(() => _nullable = value),
        Integer: () => set(() => {
            _isInteger = true;
            _isDecimal = false
        }),
        Decimal: () => set(() => {
            _isDecimal = true;
            _isInteger = false
        }),
        Min: (value: number) => set(() => _min = value),
        Max: (value: number) => set(() => _max = value)
    }

    function set(action: () => void) {
        action();
        return builder;
    }

    //const type = () => isInteger ? "integer" : "numeric";

    return {
        builder: builder,
        createModel: (): Forms.Model.Field => ({
            property,
            component,
            attrs: {
                "type": "number",
                "placeholder": _placeholder || undefined,
                "disabled": _disabled || undefined,
                "readonly": _readonly || undefined,
                "min": _min,
                "max": _max
            },
            rules: [
                //type(),
                !_required && !_nullable ? "mayRequired" : undefined,
                _required ? "required" : undefined,
                isNumber(_min) ? `min:${_min}` : undefined,
                isNumber(_max) ? `max:${_max}` : undefined,
                _isInteger ? "integer" : _isDecimal ? "decimal" : undefined, //"numeric",
            ].filter(Boolean).join("|"),
        })
    };
};

const dateTimeBox = (property: string, type: string) => {
    let component: Forms.Model.FieldComponent = "AunoaDateBox";

    let _placeholder: string;
    let _disabled: boolean;
    let _readonly: boolean;
    let _required: boolean;
    let _min: Date;
    let _max: Date;

    const builder: Forms.Builder.DateTimeBox = {
        Placeholder: (value: string) => set(() => _placeholder = value),
        Required: (value = true) => set(() => _required = value),
        Readonly: (value = true) => set(() => _readonly = value),
        Disabled: (value = true) => set(() => _disabled = value),
        Min: (value: Date) => set(() => _min = value),
        Max: (value: Date) => set(() => _max = value)
    }

    function set(action: () => void) {
        action();
        return builder;
    }

    return {
        builder: builder,
        createModel: (): Forms.Model.Field => ({
            property,
            component,
            attrs: {
                "type": type || undefined,
                "placeholder": _placeholder || undefined,
                "disabled": _disabled || undefined,
                "readonly": _readonly || undefined,
                "min": _min,
                "max": _max
            },
            rules: [
                _required ? "required" : undefined,
                //typeof _min === "number" ? `min_value:${_min}` : undefined,
                //typeof _max === "number" ? `max_value:${_max}` : undefined
            ].filter(Boolean).join("|"),
        })
    };
};

const booleanBox = (property: string) => {
    let component: Forms.Model.FieldComponent = "AunoaSelectBox";

    let _disabled: boolean;
    let _readonly: boolean;
    let _required: boolean;
    let _nullable: boolean;
    let _inverted: boolean;
    let _falseText: string;
    let _trueText: string;
    let _nullText: string;
    let _falseIcon: string;
    let _trueIcon: string;

    const getFalseText = (key = "YesNo") => `@@Aunoa.Boolean.${key}.False`;
    const getTrueText = (key = "YesNo") => `@@Aunoa.Boolean.${key}.True`;

    const setTranslation = (key: string) => {
        _falseText = getFalseText(key);
        _trueText = getTrueText(key);
        if (key === "Lock") {
            _falseIcon = "far fa-lock-open";
            _trueIcon = "fas fa-lock text-muted";
        }
        return builder;

        //return setBoolean(getFalseText(key), getTrueText(key));
    };

    const builder: Forms.Builder.BooleanBox = {
        Required: (value = true) => set(() => _required = value),
        Readonly: (value = true) => set(() => _readonly = value),
        Disabled: (value = true) => set(() => _disabled = value),
        Nullable: (value = true) => set(() => _nullable = value),
        Inverted: (value = true) => set(() => _inverted = value),
        FalseText: (value: string) => set(() => _falseText = value),
        TrueText: (value: string) => set(() => _trueText = value),
        NullText: (value: string) => set(() => _nullText = value),
        AsTrueFalse: () => setTranslation("TrueFalse"),
        AsYesNo: () => setTranslation("YesNo"),
        AsOnOff: () => setTranslation("OnOff"),
        AsEnabledDisabled: () => setTranslation("EnabledDisabled"),
        AsShowHide: () => setTranslation("ShowHide"),
        AsVisibleHidden: () => setTranslation("VisibleHidden"),
        AsAllowedDisallowed: () => setTranslation("AllowedDisallowed"),
        AsAllowedForbidden: () => setTranslation("AllowedForbidden"),
        AsLock: () => setTranslation("Lock")
    }

    function set(action: () => void) {
        action();
        return builder;
    }

    //function setBoolean(f: string, t: string) {
    //    _falseText = f;
    //    _trueText = t;
    //    return builder;
    //}

    return {
        builder: builder,
        createModel: (): Forms.Model.Field => ({
            property,
            component,
            attrs: {
                disabled: _disabled,
                readonly: _readonly,
                dataSource: [
                    _inverted
                        ? [false, _trueText || getTrueText(), _trueIcon /*|| "far fa-check-square"*/]
                        : [false, _falseText || getFalseText(), _falseIcon /*|| "far fa-square"*/],
                    _inverted
                        ? [true, _falseText || getFalseText(), _falseIcon /*|| "far fa-square"*/]
                        : [true, _trueText || getTrueText(), _trueIcon /*|| "far fa-check-square"*/],
                    _nullable
                        ? [null, _nullText || "@@Aunoa.Form.NoOptionSelected"]
                        : undefined
                ].filter(Boolean),
            },
            rules: [
                _required ? "required" : undefined,
            ].filter(Boolean).join("|"),
        })
    }
}

const selectBox = (property: string, dataSource: string | undefined, multi: boolean) => {
    let component: Forms.Model.FieldComponent = multi
        ? "AunoaMultiSelectBox"
        : "AunoaSelectBox";

    let _disabled: boolean;
    let _readonly: boolean;
    let _required: boolean;
    let _searchEnabled: boolean;

    const valueOptions = useLookupValueOptions();
    const displayOptions = useLookupDisplayOptions();

    const builder: Forms.Builder.SelectBox = {
        Required: (value = true) => set(() => _required = value),
        Readonly: (value = true) => set(() => _readonly = value),
        Disabled: (value = true) => set(() => _disabled = value),
        UseValue: (options: (v: LookupValueOptions) => void) => set(() => options(valueOptions.options)),
        UseDisplay: (options: (v: LookupDisplayOptions) => void) => set(() => options(displayOptions.options)),
        EnableSearch: (value = true) => set(() => _searchEnabled = value)
    }

    function set(action: () => void) {
        action();
        return builder;
    }

    return {
        builder: builder,
        createModel: (): Forms.Model.Field => ({
            property,
            component,
            attrs: {
                //"placeholder": placeholder || undefined,
                "disabled": _disabled || undefined,
                "readonly": _readonly || undefined,
                "dataSource": dataSource,
                "valueExpression": valueOptions.expression(),
                "textExpression": displayOptions.expression(),
                "displayCasing": displayOptions.casing(),
                "searchEnabled": _searchEnabled || undefined,
            },
            rules: [
                _required ? "required" : undefined,
            ].filter(Boolean).join("|"),
        })
    }
}

const prepareSelectBox = (property: string, multi: boolean) => {

    let createModel: () => Forms.Model.Field;

    const builder: Forms.Builder.PrepareSelectBox = {
        WithDataSource: (source: string | LookupFactory) => {
            const dataSource = isLookupFactory(source)
                ? source.name
                : isString(source)
                    ? source
                    : undefined;
            const box = selectBox(property, dataSource, multi);
            createModel = box.createModel;
            return box.builder;
        },
    }

    return {
        builder: builder,
        createModel: () => createModel()
    }

}

const fileBox = (property: string) => {
    let component: Forms.Model.FieldComponent = "AunoaFileBox";

    let _accept: string;
    let _url: string;
    let _uploadMultiple: boolean;
    let _disabled: boolean;
    let _readonly: boolean;
    let _required: boolean;

    const builder: Forms.Builder.FileBox = {
        Required: (value = true) => set(() => _required = value),
        Readonly: (value = true) => set(() => _readonly = value),
        Disabled: (value = true) => set(() => _disabled = value),
        Accept: (value: string) => set(() => _accept = value),
        Url: (value: string) => set(() => _url = value),
        UploadMultiple: (value = true) => set(() => _uploadMultiple = value),
    }

    function set(action: () => void) {
        action();
        return builder;
    }

    return {
        builder,
        createModel: (): Forms.Model.Field => ({
            property,
            component,
            attrs: {
                "disabled": _disabled || undefined,
                "readonly": _readonly || undefined,
                "acceptedFiles": _accept,
                "url": _url,
                "uploadMultiple": _uploadMultiple || undefined,
            },
            rules: [
                _required ? "required" : undefined,

            ].filter(Boolean).join("|") || undefined
        })
    };
}


const _useField = <T = any>(register: (createFieldModel: () => Forms.Model.Field) => void, baseModel?: string) => {

    const getModel = (propertyName: any) => baseModel
        ? `${baseModel}.${propertyName}`
        : propertyName;

    function get<TC>(getProperty: (obj: T) => any, create: CreateFunc<TC>): TC {
        const propertyName = getPropertyName<T>(getProperty);
        const model = getModel(propertyName);
        const control = create(model);
        register(control.createModel);
        return control.builder;
    }

    let fieldBuilder: Forms.Builder.FieldBase<T> = {
        Property<TP extends T[keyof T]>(getter: (o: T) => TP) {
            const model = getModel(getPropertyName<T, TP>(getter));
            return _useField<TP>(register, model);
        },
        TextBox: (gp: (o: T) => string) => get(gp, m => textBox(m)),
        NumberBox: (gp: (o: T) => number | null) => get(gp, m => numberBox(m)),
        DateTimeBox: (gp: (o: T) => Date | null) => get(gp, m => dateTimeBox(m, "datetime-local")),
        DateBox: (gp: (o: T) => Date | null) => get(gp, m => dateTimeBox(m, "date")),
        TimeBox: (gp: (o: T) => Date | null) => get(gp, m => dateTimeBox(m, "time")),
        BooleanBox: (gp: (o: T) => boolean | null) => get(gp, m => booleanBox(m)),
        SelectBox: (gp: (o: T) => any) => get(gp, m => prepareSelectBox(m, false)),
        MultiSelectBox: (gp: (o: T) => any[]) => get(gp, m => prepareSelectBox(m, true)),
        FileBox: (gp: (o: T) => string) => get(gp, m => fileBox(m)),
    }
    return fieldBuilder;
};

export const useField = <T = any>() => {

    let _label: string | boolean = true;
    let _name: string;
    let _required: Conditions.Model.Condition;
    let _readonly: Conditions.Model.Condition;
    let _disabled: Conditions.Model.Condition;

    let createFieldModel: () => Forms.Model.Field;

    const condition = (value: any, defaultValue: Conditions.Model.Condition): Conditions.Model.Condition => {
        if (isFunction(value)) {
            const {builder: conditionsBuilder, build} = createConditionsModel();
            value(conditionsBuilder);
            return build();
        }
        return isUndefined(value) || value
            ? true
            : defaultValue;
    }

    const fieldBuilder: Forms.Builder.Field<T> = {
        Label: (value: string | boolean) => set(() => _label = value),
        Name: (value: string) => set(() => _name = value),
        Required: (value: any) => set(() => _required = condition(value, _required)),
        Readonly: (value: any) => set(() => _readonly = condition(value, _readonly)),
        Disabled: (value: any) => set(() => _disabled = condition(value, _disabled)),
        ..._useField<T>(value => createFieldModel = value)
    }

    const set = (action: () => void) => {
        action();
        return fieldBuilder;
    }

    const create = (name: string, o: any) => isBoolean(o) || isObject(o) ? {[name]: o} : undefined

    return {
        builder: fieldBuilder,
        createModel: (): Forms.Model.Field => ({
            name: _name,
            label: _label,
            ...createFieldModel?.(),
            ...create("required", _required),
            ...create("readonly", _readonly),
            ...create("disabled", _disabled)
        })
    };
};

export const useRow = <T = any>() => {
    const _fields: FieldsHelper[] = [];

    const rowBuilder: Forms.Builder.Row<T> = {
        Field: (options: (f: Forms.Builder.Field<T>) => void) => {
            const field = useField<T>();
            options(field.builder);
            _fields.push(field);
            return rowBuilder
        }
    }

    return {
        builder: rowBuilder,
        createModel: () => _fields.map(field => field.createModel())
    }
};

export const useGroup = <T = any>(name: string) => {

    const _rowsAndFields: RowsAndFieldsHelper[] = [];

    let _label: string;

    const groupBuilder: Forms.Builder.Group<T> = {
        Label: (label) => {
            _label = label;
            return groupBuilder
        },
        Row: (options) => {
            const row = useRow<T>();
            options(row.builder);
            _rowsAndFields.push(row);
            return groupBuilder
        },
        Field: (options) => {
            const field = useField<T>();
            options(field.builder);
            _rowsAndFields.push(field);
            return groupBuilder
        },
        Loop: (arrayGetter, groups) => {
            const properties: string[] = [];
            const builder = buildArrayProperty(properties);
            arrayGetter?.(<any>builder);

            const subGroup = useGroup(name + "_" + properties.join("_"));
            groups(subGroup.builder);

            _rowsAndFields.push(<any>{
                name: () => subGroup.name(),
                createModel: () => ({
                    "array": properties.join("."),
                    ...subGroup.createModel()
                })
            });
            return groupBuilder
        }
    }

    return {
        builder: groupBuilder,
        name: () => name,
        createModel: () => ({
            label: _label,
            fields: _rowsAndFields.map(rowOrField => rowOrField.createModel())
        })
    }
};


export const createFormModel = <T = any>(options?: FormModelOptions): Forms.Builder.Form<T> => {
    const _groups: GroupHelper[] = [];

    const formBuilder: Forms.Builder.Form<T> = {
        Group: (name: string, options: (g: Forms.Builder.Group<T>) => void) => {
            const group = useGroup<T>(name);
            options(group.builder);
            _groups.push(group);
            return formBuilder;
        },
        CustomGroup: (name: string, label: string, component: string) => {
            _groups.push({
                name: () => name,
                createModel: () => ({
                    label: label,
                    fields: [],
                    component: component
                })
            });
            return formBuilder;
        },
        Build: () => {
            return _groups.reduce((groups, {name, createModel}) => {
                groups[name()] = createModel();
                return groups;
            }, <Forms.Model.Form>{})
        }
    }

    return formBuilder
};