import type {Ref} from "vue";

import {useMate} from "./useMate";
import {createAuthorizationHeader} from "./base/useEndpoint";

export const useAuthorizationHeader = (accessToken: Ref<string | undefined>) => {

    const {isMateUrl} = useMate()

    return {
        createAuthorizationHeader: (url: string) =>
            isMateUrl(url)
                ? createAuthorizationHeader(accessToken)
                : undefined
    }

}