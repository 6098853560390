﻿import Vue, {Ref, ref, readonly, nextTick, computed, unref, reactive, watch, shallowRef, triggerRef} from "vue";
import {DataService} from "bootstrap-aunoa";
import {useDataService} from "./useDataService";
import {useElementVisibility} from "./useElementVisibility";

export const useInfinitePaging = (
    crudService: Ref<DataService>,
    pageSize: Ref<number>
) => {

    const {readList, read} = useDataService(crudService)
    const {element: infiniteElement, visible: endVisible} = useElementVisibility();

    const _hasMore = ref(false);
    const _started = ref(false);
    const _error = shallowRef();
    const _skip = shallowRef(0);
    const _busy = shallowRef(false);
    const _fetching = shallowRef(false);
    const _entities = shallowRef<any[]>([]);
    const completed = computed(() => !_hasMore.value && _started.value);

    const hasEntities = computed(() => _entities.value.length > 0);
    const hasNoEntities = computed(() => _started.value && !_fetching.value && !_hasMore.value && _entities.value.length === 0);

    const _count = computed(() => _entities.value.length);
    const _promise = ref<Promise<any[]>>();

    const addRecords = (list: any[], taken: number) => {
        _hasMore.value = list.length > taken;
        if (_hasMore.value) {
            list = list.filter((o, i) => i < taken);
        }
        _skip.value += list.length;
        _entities.value.push(...list);
        triggerRef(_entities);
        return list;
    };

    const _reset = () => {
        _skip.value = 0;
        _busy.value = false;
        _hasMore.value = false;
        _error.value = undefined;
        _fetching.value = false;
    }

    const reset = () => {
        _reset();
        _entities.value = [];
        _started.value = false;
    }

    //const isQuery = (value: TQuery|TKey): value is TQuery => typeof value === "object";

    let _query: any;

    const ensureFinished = () => {
        //console.log("fetched busy=false");
        _busy.value = false;
        setTimeout(() => {
            //console.log("fetched fetching=false");
            _fetching.value = false;
        }, 250);
    };
    
    const mayResetOnError = (error:any) => {
        if (error.httpStatus === 401 || error.httpStatus === 403) {
            reset();
        }
    }

    const start = (query: any, key?: any): Promise<any[]> => {
        _reset();
        _started.value = true;
        _fetching.value = true;

        //console.log("start");
        const delayedBusyHandler = window.setTimeout(() => {
            _entities.value = [];
            _busy.value = true;
        }, 250);

        const toTake = pageSize.value;
        _query = {...query};

        const ensureData = (list: any[]) => {
            window.clearTimeout(delayedBusyHandler);
            _entities.value = [];
            return addRecords(list, toTake);
        }

        const ensureError = (error: any) => {
            window.clearTimeout(delayedBusyHandler);
            mayResetOnError(error);
            _error.value = error;
            throw error;
        }

        if (query) {
            _promise.value = readList(_query, _skip.value, toTake + 1);
            return _promise.value
                .then(ensureData)
                .catch(ensureError)
                .finally(ensureFinished);
        } else if (key !== undefined && key !== null) {
            _promise.value = read(key);
            return _promise.value
                .then(entity => ensureData([entity]))
                .catch(ensureError)
                .finally(ensureFinished);
        } else {
            _promise.value = Promise.reject("Invalid parameters");
            return _promise.value;
        }
    };

    const more = (take?: number) => {
        _fetching.value = true;

        const delayedBusyHandler = window.setTimeout(() => {
            _busy.value = true;
        }, 250);

        const toTake = take || pageSize.value;

        const ensureData = (list: any[]) => {
            window.clearTimeout(delayedBusyHandler);
            addRecords(list, toTake);
        }

        const ensureError = (error: any) => {
            window.clearTimeout(delayedBusyHandler);
            mayResetOnError(error);
            _error.value = error;
        }

        _promise.value = readList(_query, _skip.value, toTake + 1);
        _promise.value
            .then(ensureData)
            .catch(ensureError)
            .finally(ensureFinished);
    };

    watch([_started, _hasMore, endVisible, _fetching, _error], ([started, hasMore, atEnd, fetching, error]) => {
        //console.log(started ? "started" : "", hasMore ? "more" : "", atEnd ? "at-end" : "", fetching ? "" : "not-fetching", error ? "error" : "");
        if (started && hasMore && atEnd && !fetching && !error) {
            //console.log(".........>>>>>> paging.more from watch")
            more();
        }
    });

    const set = (entity: any, index: number) => {
        _entities.value.splice(index, 1, entity);
    }
    
    const replaceAll = (entities: any[]) => {
        _entities.value = entities;
        _started.value = true;
    }

    const remove = (index: number) => {
        _entities.value.splice(index, 1).length;
        triggerRef(_entities);
    }

    const insert = (entity: any, index = 0) => {
        _entities.value.splice(index, 0, entity);
        _started.value = true;
        triggerRef(_entities);
    }

    const currentQuery = () => _query;

    return {
        busy: readonly(_busy),
        error: readonly(_error),
        hasMore: readonly(_hasMore),
        fetching: readonly(_fetching),
        started: readonly(_started),
        entities: readonly(_entities),
        promise: readonly(_promise),
        count: _count,

        completed,
        hasEntities,
        hasNoEntities,

        currentQuery,
        infiniteElement,
        endVisible,


        set,
        more,
        reset,
        start,
        remove,
        insert,
        replaceAll
    }

};