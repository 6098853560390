﻿import {AccessToken} from "./types";
import {Ref, unref} from "vue";
import {useMateApi} from "./useMateApi";
import {getTenantOidFromEntity} from "./useTenancy";

interface CrudReadOptions {
    skip: number;
    take: number;
}

export enum DataGroup {
    PosOperating = "pos-operating",
    PosPreferences = "pos-preferences",
    PosSupply = "pos-supply",
    Identity = "identity",
    Tenancy = "tenancy",
    Campaigns = "campaigns"
}

export enum DataType {
    Tenant = "tenant",
    Location = "location",
    LocationGroup = "location-group",
    PosTerminal = "pos-terminal",
    PanTerminal = "pan-terminal",
    Operator = "operator",
    DocumentNumber = "document-number",
    ExpenseTemplate = "expense-template",
    DepositTemplate = "deposit-template",
    CancellationReason = "cancellation-reason",
    DocumentAbortingReason = "document-aborting-reason",
    DocumentItemCharacteristicType = "document-item-characteristic-type",
    InventoryReason = "inventory-reason",
    ReturnReason = "return-reason",
    RepairReason = "repair-reason",
    OpenCashDrawerReason = "open-cash-drawer-reason",
    DiscountReason = "discount-reason",
    ReservationRejectReason = "reservation-reject-reason",
    IncomingDemandRejectReason = "incoming-demand-reject-reason",
    RelocationTransportType = "relocation-transport-type",
    Customer = "customer",
    Title = "title",
    Salutation = "salutation",
    Article = "article",
    User = "user",
    Role = "role",
    ApiAccess = "api-access",
    Promotion = "promotion",
    ReportEngine = "report-engine",
    ContactTask = "contactTask",
    DocumentLock = "document-lock",
    QuantityUnit = "quantity-unit",
    PackingUnit = "packing-unit",
    Tax = "tax",
}

interface Entity extends Record<string, any> {
    Oid: number;
    ETag?: string;
    Disabled?: boolean;
}

const PRESERVE_SCOPE = true;

const ensureParams = (params: any) =>
    Object.entries(params || {}).reduce((p, [key, value]) => {
        if (key.endsWith("Oid") && typeof value === "string") {
            value = parseInt(<string>value);
        }
        p[key] = Array.isArray(value) ? value.join(",") : value;
        return p
    }, <Record<string, any>>{});


export const useDataApi = (
    dataGroup: string | Ref<DataGroup>,
    dataType: string | Ref<DataType>,
    tenantOid: number | Ref<number>,
    accessToken: AccessToken) => {

    const getDataGroup = () => unref(dataGroup);
    const getDataType = () => unref(dataType);
    const {jsonEndpoint} = useMateApi(accessToken, tenantOid)
    const url = () => `/api/data/${getDataGroup()}/${getDataType()}`;
    const api = () => jsonEndpoint(url());
    const apiPS = () => jsonEndpoint(url(), PRESERVE_SCOPE);
    const pascalCase = true;
    const getParams = () => ({
        tenantOid: unref(tenantOid)
    });


    return {
        key: <TEntity extends Entity = Entity>(entity: TEntity) => entity.Oid,

        etag: <TEntity extends Entity = Entity>(entity: TEntity) => entity.ETag,

        disabled: <TEntity extends Entity = Entity>(entity: TEntity) => entity.Disabled === true,

        empty: <TEntity = any>(params: any) =>
            apiPS().get<TEntity[]>({
                ...ensureParams(params),
                ...getParams()
            }, {url: "/empty", pascalCase}),

        adjust: <TEntity extends Entity = Entity>(params: any, entity: TEntity): Promise<TEntity> =>
            apiPS().post<TEntity, TEntity>(entity, {
                ...ensureParams(params),
                ...getParams()
            }, {url: "/adjust", pascalCase}),

        create: <TEntity extends Entity = Entity>(entity: TEntity): Promise<TEntity> =>
            apiPS().post<TEntity, TEntity>(entity, getParams(), {pascalCase}),

        read: <TEntity = any>(oid: number, etag?: string, entity?: TEntity) =>
            apiPS().get<TEntity>(getParams(), {url: `/${oid}`, ifNoneMatch: etag, pascalCase}),

        readList: <TEntity = any>(params: any, options: CrudReadOptions) =>
            apiPS().get<TEntity[]>({
                ...ensureParams(params),
                skip: options.skip,
                take: options.take
            }, {pascalCase}),

        update: <TEntity = any>(oid: number, entity: TEntity, etag?: string): Promise<TEntity> =>
            apiPS().put<TEntity, TEntity>(entity, getParams(), {url: `/${oid}`, ifMatch: etag, pascalCase}),

        delete: <TEntity = any>(oid: number, etag?: string): Promise<void> =>
            api().delete(getParams(), {url: `/${oid}`, ifMatch: etag}),

        readDetails: <TEntityDetails = any>(oid: number, name: string, etag?: string) =>
            apiPS().get<TEntityDetails>(getParams(), {url: `/${oid}/details/${name}`, ifMatch: etag, pascalCase}),

        readMeta: <TMeta = any>() =>
            api().get<TMeta>(getParams(), {url: "/meta"}),

        readFormExtensions: <T = any>() =>
            api().get<T>(getParams(), {url: "/form-extensions"}),

        bulkCreate: (obj: any, template?: string): Promise<any> =>
            apiPS().post<number>(obj, {tenantOid: getTenantOidFromEntity(obj) ?? unref(tenantOid)}, 
                {url: `/bulk-create/${template ?? "default"}`, pascalCase}),

        patch: <T = any>(oid: number) =>
            api().patch<T>(null, getParams(), {url: `/${oid}`})

    }

};