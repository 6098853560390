﻿import type {Ref, WatchStopHandle} from "vue";
import {ref, readonly, watch, computed} from "vue";
import {singleton} from "./base/singletons";
import {isString} from "./useDownloadHelper";

interface TenancyInfo {
    rootTenantOid: number;
    primaryTenantOid: number;
    tenantOids: number[];
}

/*
const caseA = { // Admin of all
    rootTenantOid: 1,
    tenantOids: [1]
}

const caseB = { // Controller in DE and GB 
    rootTenantOid: 1,
    tenantOids: [2, 4]
}

const caseC = {  // Controller global, Finance AT 
    rootTenantOid: 1,
    tenantOids: [1, 9]
}

const caseSass = {  // Admin Bäckerei Euler 
    rootTenantOid: 237,
    tenantOids: [237]
}
*/

let _storagePrimaryTenantOid: Ref<number>;
let _unwatchStorage: WatchStopHandle;

const _rootTenantOid = ref(0);
//const _fixedTenantOid = ref(0);
const _primaryTenantOid = ref(0);
const _isTenancyVisible = ref(false);
const _tenantOids = ref<number[]>();


const primaryTenantOid = computed<number>({
    get: () => {
        let oid = _primaryTenantOid.value;
        const oids = _tenantOids.value;
        if (oid && oids && !oids.includes(oid)) {
            oid = 0;
        }
        oid = oid || oids?.[0] || 0;
        return oid;
    },
    set: oid => {
        _primaryTenantOid.value = oid;
        _storagePrimaryTenantOid.value = oid;
    }
});


export const setupPrimaryTenancy = (storagePrimaryTenantOid: Ref<number>) => {
    _storagePrimaryTenantOid = storagePrimaryTenantOid;
    _unwatchStorage?.();
    _unwatchStorage = watch(_storagePrimaryTenantOid, oid => {
        _primaryTenantOid.value = oid;
    }, {immediate: true});
}

export const setTenancy = (info: TenancyInfo) => {
    //console.log(info);
    _rootTenantOid.value = info.rootTenantOid;
    _tenantOids.value = info.tenantOids;
    if (info.tenantOids?.length) {
        _isTenancyVisible.value = info.tenantOids?.length > 1;
        //_fixedTenantOid.value =

    } else {
        _isTenancyVisible.value = info.primaryTenantOid >= 0;
        //_fixedTenantOid.value = info.primaryTenantOid > 1 ? info.primaryTenantOid : 0;
    }
}

export const useTenancyFromProps = (tenantOid: Ref<number | undefined>) => {

    const currentTenantOid = ref(0);

    watch(
        [tenantOid, _primaryTenantOid, _rootTenantOid],
        ([tOid, ptOid, rtOid]) => {
            currentTenantOid.value = tOid || ptOid || (_tenantOids.value?.length === 1 ? _tenantOids.value[0] : rtOid);
        },
        {immediate: true}
    );

    return {
        rootTenantOid: readonly(_rootTenantOid),
        primaryTenantOid: readonly(_primaryTenantOid),
        currentTenantOid: readonly(currentTenantOid),
        isTenancyVisible: readonly(_isTenancyVisible)
    }
}


export const getTenantOidFromEntity = (entity: any): number => {
    const tenantOid = entity.TenantOid === null || entity.tenantOid === null
        ? undefined
        : entity.TenantOid || entity.tenantOid || entity.$$scope?.tenantOid || currentTenantOid.value;
    return isString(tenantOid)
        ? Number.parseInt(tenantOid)
        : tenantOid;
}

export const useTenantOidFromEntity = () => {

    const _entity = ref<any>({});

    return {
        tenantOid: computed(() => getTenantOidFromEntity(_entity.value)),

        setEntity: (entity: any) => {
            _entity.value = entity;
            //const _ = _tenantOid.value; // <--- required for correct vue lifecycle and update cycle
        }
    }

}

export const useCachedTenancyDataFromProps = <T = any>(tenantOid: Ref<number | undefined>, cacheName: string, create: () => T) => {

    type Dict = Record<number, T>;

    const dict = singleton<Ref<Dict>>(cacheName, () => ref({}));
    const tenancy = useTenancyFromProps(tenantOid);
    const {currentTenantOid} = tenancy;

    const data = <Ref<T>>ref<T>();
    watch(currentTenantOid, oid => data.value = dict.value[oid]
        ? dict.value[oid]
        : dict.value[oid] = create(), {immediate: true})

    return {
        data,
        ...tenancy
    }
}


const _currentTenantOid = ref<number>();
const getCurrentTenantOid = () => {
    //console.log("getCurrentTenantOid", _currentTenantOid.value, _primaryTenantOid.value, _rootTenantOid.value)
    return _currentTenantOid.value || _primaryTenantOid.value || _rootTenantOid.value;
};

const currentTenantOid = computed<number>({
    get: getCurrentTenantOid,
    set: oid => _currentTenantOid.value = oid
});

export const useTenancy = () => {

    _currentTenantOid.value = undefined;
    return {
        primaryTenantOid,
        tenantOids: _tenantOids,
        rootTenantOid: readonly(_rootTenantOid),
        isTenancyVisible: readonly(_isTenancyVisible),
        currentTenantOid
    }
}

export const useCurrentTenant = () => {

    return {
        currentTenantOid: computed(getCurrentTenantOid)
    }
}


export const usePrimaryTenancyEntity = <T extends { TenantOid: number } = any>(entity: Ref<T | undefined>) => {
    const {primaryTenantOid} = useTenancy();
    const _entity = ref<T>();
    watch(entity, newEntity => {
        _entity.value = {
            TenantOid: primaryTenantOid.value,
            ...newEntity
        } as T;
    }, {immediate: true})
    
    return {
        entity: _entity
    }
}
