﻿<template>
    <li ref="itemElement" :class="itemClass">
        <component :is="as" ref="buttonElement" v-bind="linkAttrs" @click="onClick">
            <slot name="link">
                <i :class="icon" v-if="icon"/>
                <span v-if="text" v-text="ensureTextTranslated(text)"/>
                <span
                    v-if="optionalFormValidationErrorCount>9"
                    class="badge badge-sm rounded-pill bg-danger ms-1"
                    v-text="optionalFormValidationErrorCount"
                />
                <i
                    v-else-if="optionalFormValidationErrorCount>0"
                    class="fas text-danger ps-1"
                    :class="['fa-circle-'+optionalFormValidationErrorCount]"
                />
                <span class="busy" v-if="busyIcon">
                    <i class="fa-lg" :class="busyIcon"/>
                </span>
            </slot>
            <div
                v-if="title"
                ref="tooltipElement"
                class="position-absolute"
                style="inset: 0"
            />

        </component>
        <component
            v-if="$slots.default"
            :is="dropdownTag"
            class="dropdown-menu"
            :class="dropMenuClass"
            :aria-labelledby="$attrs.id">
            <aunoa-dropdown-header :text="ensureTextTranslated(headerText)" v-if="headerText"/>
            <slot/>
        </component>
    </li>
</template>

<script lang="ts">

import {PropType} from "vue";
import type {PromisableEvent} from "../../types";

import {useFormValidationOptional} from "../../implementations/forms/useValidation";
import {computed, defineComponent, toRefs, ref, watch, onMounted, onBeforeUnmount, onUpdated} from "vue";
import {useEmitableVisibilityEvents} from "../../utils/useVisibilityEvents";
import AunoaSelectNavItemMixin from "../../mixins/AunoaSelectNavItemMixin";
import AunoaDropdownHeader from "../dropdown/AunoaDropdownHeader.vue";
import {createPromisableEvent} from "../../utils/promisableEvents";
import {useAunoaI18n} from "../../utils/useAunoaI18n";
import {useIconAlias} from "../../utils/useIconAlias";
import {useBusy} from "../../utils/useBusy";
import {Tooltip} from "bootstrap";


export default defineComponent({
    name: "AunoaNavItem",
    inheritAttrs: false,
    mixins: [AunoaSelectNavItemMixin],
    components: {
        AunoaDropdownHeader
    },
    props: {
        as: {
            default: "button"
        },
        dropdownTag: {
            default: "ul"
        },
        text: {
            type: String
        },
        title: {
            type: String
        },
        icon: {
            default: undefined
        },
        itemClass: {
            type: String,
            default: undefined
        },
        raiseClickOnMounted: {
            type: Boolean,
            default: false
        }
    },
    emits: ["click", "show-dropdown", "dropdown-shown", "hide-dropdown", "dropdown-hidden"],
    setup: function (props, {slots, attrs, emit}) {
        const {
            text,
            dropDirection,
            disabled,
            icon: iconProp,
            itemClass: _itemClass,
            raiseClickOnMounted
        } = toRefs(props);

        const isDropdown = () => !!slots.default;

        const isSubmitType = computed(() => attrs.type === "submit");
        const {validationErrorCount: optionalFormValidationErrorCount} = useFormValidationOptional(isSubmitType);
        const buttonElement = ref<HTMLElement>();
        const tooltipElement = ref<HTMLElement>();
        const itemElement = ref<HTMLElement>();

        const {icon} = useIconAlias(iconProp);
        useEmitableVisibilityEvents("dropdown", emit, {element: itemElement});

        const {ensureTextTranslated} = useAunoaI18n()
        const {busyIcon, emitPromisableEvent} = useBusy();

        const itemClass = computed(() => [
            "nav-item",
            isDropdown() ? dropDirection.value : undefined,
            _itemClass.value
        ].filter(Boolean));

        const linkAttrs = computed<any>(() => ({
            "href": props.as === "a" ? "#" : undefined,
            "type": props.as === "button" ? "button" : undefined,
            "role": isDropdown() ? "button" : undefined,
            "data-bs-toggle": isDropdown() ? "dropdown" : undefined,
            "data-bs-offset": isDropdown() ? "-1,0" : undefined,
            "aria-expanded": isDropdown() ? "false" : undefined,
            "aria-disabled": disabled.value ? "true" : undefined,
            ...attrs,
            "title": "",
            class: [
                props.as === "span" ? "nav-text" : "nav-link",
                isDropdown() ? "dropdown-toggle" : undefined,
                disabled.value ? "disabled" : undefined,
                busyIcon.value ? "busy" : undefined,
                attrs.class
            ].filter(Boolean)
        }));

        const emitClick = (event: Event) => {
            const promisableEvent = createPromisableEvent(event);
            return emitPromisableEvent(promisableEvent, emit);
        };

        const onClick = (event: Event) => {
            if (!isSubmitType.value) {
                event.preventDefault();
                emitClick(event)
            }
        };

        const click = () => buttonElement.value?.click();

        onMounted(() => {
            const button = <HTMLElement>buttonElement.value;
            (<any>button).$$ = {
                emitClick
            }

            if (raiseClickOnMounted.value) {
                button.click();
            }

            if (tooltipElement.value) {
                const tooltip = new Tooltip(tooltipElement.value, {
                    title: () => props.title as string,
                    placement: "top"
                });
                onUpdated(() => tooltip.update());
            }
        });

        return {
            ensureTextTranslated,
            optionalFormValidationErrorCount,
            tooltipElement,
            buttonElement,
            itemElement,
            itemClass,
            linkAttrs,
            busyIcon,
            icon,
            click,
            onClick
        }

    }
});

</script>

