﻿import Showdown from "showdown";

const defaultOptions = Showdown.getDefaultOptions();
defaultOptions.tables = true;
const defaultConverter = new Showdown.Converter(defaultOptions);

export const useMarkdown = () => {

    return {
        makeHtml: (markdown: string) => defaultConverter.makeHtml(markdown)
    }
}

