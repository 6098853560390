﻿import type {Ref} from "vue";
import type {Entities} from "../types";

import {reactive, watch, ref} from "vue";
import {isArray, isDefined, isFunction, isUndefined} from "./inspect";

const resetValue = (property: Entities.Model.Property) => {
    if (isDefined(property.defaultValue)) {
        return property.defaultValue;
    }
    if (property.nullable) {
        return null;
    }
    if (property.type === "Number") {
        return 0;
    }
    if (property.type === "Boolean") {
        return false;
    }
    if (property.type === "Object") {
        return {};
    }
    if (property.type === "Array") {
        return [];
    }
    return null;
};

interface PrepareOptions {
    resetValue?: boolean | "xx";
}


export const prepareEntity = (entity: any, properties: Entities.Model.Property[] | undefined): any => {
    //console.log(obj);
    properties?.forEach(p => {

        if (p.type === "Object" && isArray(p.properties)) {
        }


        //if (isUndefined(entity[property.name]) && property.managedType) {
        //
        //    obj[property.name] = resetValue(property);
        //}
    });


    return entity;
};


export const createReactiveEntity = (model: Entities.Model.Property[] | undefined, obj: any, copyFrom: any = undefined): any => {

    const entity = reactive(obj);

    const resetOnPropertyChange = (property: Entities.Model.Property, model: string) => {
        watch(() => entity[model], (newValue, oldValue) => {
            // must be != instead of !==, otherwise type corrections would affect reset. 
            if (newValue != oldValue) {
                //console.log("resetOnPropertyChange", model, newValue, oldValue, "reset", property.name);
                entity[property.name] = resetValue(property);
            }
        });
    };

    model?.forEach(property => {
        if (isUndefined(obj[property.name])) {
            obj[property.name] = isDefined(copyFrom?.[property.name])
                ? copyFrom[property.name]
                : resetValue(property);
        }
        if (property.resetOnPropertyChange) {
            resetOnPropertyChange(property, property.resetOnPropertyChange);
        }
    });

    //console.log(JSON.stringify(entity,null,4));
    return entity;
};


export const createEntity = (model: Entities.Model.Property[] | undefined) => createReactiveEntity(model, {});


export const useEntityModel = (
    model: Ref<Entities.Model.Property[]>,
    entity: Ref<any>,
    onChange?: Function | Function[]
) => {
    const _entity = ref();

    watch([entity, model], ([newEntity, newModel]) => {
        _entity.value = createReactiveEntity(newModel, {...newEntity});
        if (onChange) {
            if (isFunction(onChange)) {
                onChange(_entity.value);
            }
        }

    }, {immediate: true})


    return {
        entity: _entity
    }

}



