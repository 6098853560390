<style lang="scss">

//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import "./scss/mate.scss";

.fade-enter-active,
.fade-leave-active {
    transition: opacity 5.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

</style>

<template>
    <div class="bg-loading" v-if="loading">
        <div class="hourglass-container bg-loading">
            <aunoa-hourglass icon-style="fat" style="font-size: 4rem" />
        </div>
    </div>
    <router-view v-else />
</template>

<script lang="ts">

import {provideAccessToken, useAuthorizationHeader, useMate} from "@tnt/mate-api";
import {AunoaHourglass, provideUrlTools, useAunoaI18nController, useI18nController} from "bootstrap-aunoa";
import {defineComponent, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {useAuth} from "./utils/useAuth";
import axios from "axios";

export default defineComponent({
    name: "App",
    components: {AunoaHourglass},
    setup() {

        //onMounted(()=>;

        useAunoaI18nController();
        const {useI18n, registerI18n, isReady} = useI18nController({
            loadMessage: async locale => {
                //console.log("loading", `/loc/${locale}.json`);
                const cldrPromise = axios.get(`/assets/cldr/${locale}.json`);
                const localePromise = axios.get(`/loc/${locale}.json`);
                //.then(result => console.log(result));
                let result = (await localePromise).data;
                try {
                    await cldrPromise.then(r => result = {...result, ...r.data});
                }
                catch {
                }
                //console.log(JSON.stringify(Object.keys(result.territories)));
                return result;
            }
        })

        const i18n = useI18n("global");
        registerI18n(i18n);

        const {accessToken} = useAuth();
        provideAccessToken(accessToken);

        const {ensureUrl} = useMate();
        const {createAuthorizationHeader} = useAuthorizationHeader(accessToken);
        provideUrlTools({
            createAuthorizationHeader,
            ensureUrl,
        });

        const router = useRouter();
        const loading = ref(true);
        //const timer = window.setTimeout(() => loading.value = true, 0);

        Promise.all([isReady(), router.isReady()]).finally(() => {
            //window.clearTimeout(timer);
            loading.value = false;
        })

        return {
            loading
        }
    }
});


</script>


