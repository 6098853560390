﻿import type {InjectionKey} from "vue"
import {inject, provide} from "vue"

export interface UrlTools {
    createAuthorizationHeader(url: string): Record<string, string> | undefined;

    ensureUrl(url: string): string;

}

const INJECTION_KEY: InjectionKey<UrlTools> = Symbol("AUNOA_URL_TOOLS");

export const provideUrlTools = (tools: UrlTools) => provide(INJECTION_KEY, tools);

export const useUrlTools = (): UrlTools => inject(INJECTION_KEY) || {
    createAuthorizationHeader: _ => undefined,
    ensureUrl: url => url
}

