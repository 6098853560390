﻿<!--suppress HtmlFormInputWithoutLabel, ES6PreferShortImport -->

<template>
    <div v-bind="fieldAttrs">
        <slot name="label">
            <aunoa-form-label v-if="label" v-bind="labelAttrs" />
        </slot>
        <aunoa-dropzone
            :url="url"
            :accepted-files="acceptedFiles"
            :upload-multiple="uploadMultiple"
            :parallel-uploads="6"
            :max-files="1"
            @select="onSelect"
        />
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {fieldProps, useField} from "../../implementations/forms/useField";
import AunoaFormLabel from "./AunoaFormLabel.vue";
import AunoaDropzone from "../AunoaDropzone.vue";
import AunoaErrorMessage from "./AunoaErrorMessage.vue";


export default defineComponent({
    name: "AunoaFileBox",
    inheritAttrs: false,
    components: {AunoaFormLabel, AunoaDropzone, AunoaErrorMessage},
    props: {
        ...fieldProps,
        url: {
            type: String
        },
        acceptedFiles: {
            type: String
        },
        uploadMultiple: {
            type: Boolean,
            default: false
        }
    },
    setup(props, ctx) {

        const field = useField(props as any, ctx)

        const onSelect = (files: any, key: any) => {
            field.value.value = key;
        }

        return {
            ...field,
            onSelect
        }
    }
});

</script>
