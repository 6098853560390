// noinspection SpellCheckingInspection

import type {Ref} from "vue";
import type {ApiError} from "./error";
import type {AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError, AxiosBasicCredentials} from "axios";

import {useUserRelogin} from "../useUserRelogin";
import {isAccessTokenValid} from "./accessToken";
import {AccessToken} from "../types";
import {ensureApiError} from "./error";
import {ref, unref} from "vue";

type MultipartType = "mixed";

type Send<TResponse> = (url: string, config: AxiosRequestConfig) => Promise<AxiosResponse<TResponse>>;
type SendData<TResponse> = (url: string, data: any, config: AxiosRequestConfig) => Promise<AxiosResponse<TResponse>>;

interface MultipartOptions {
    type?: MultipartType;
    detailed?: boolean;
}

export interface Options {
    url?: string;
    headers?: any;
    ifMatch?: string | boolean;
    ifNoneMatch?: string | boolean;
    multiPart?: "mixed" | MultipartOptions;
    auth?: AxiosBasicCredentials;
}

interface Multipart {
    content: string;
    headers: Record<string, any>;
}

const ignoreDuplicateOf = [
    "age", "authorization", "content-length", "content-type", "etag",
    "expires", "from", "host", "if-modified-since", "if-unmodified-since",
    "last-modified", "location", "max-forwards", "proxy-authorization",
    "referer", "retry-after", "user-agent"
];

const isObject = (value: any): value is object => typeof value === "object";
const isMultipartOptions = (value: any): value is MultipartOptions => typeof value === "object" && (value.type || value.detailed);

export const createAuthorizationHeader = (accessToken: AccessToken | Ref<AccessToken>): Record<string, string> | undefined => {
    accessToken = unref(accessToken);
    return accessToken && accessToken !== "ANONYMOUS"
        ? {"Authorization": `Bearer ${accessToken}`}
        : undefined;
};

export const createAcceptLanguageHeader = (locale: string | undefined) => locale
    ? {"Accept-Language": locale}
    : undefined;

const parseHeaders = (headers: string) => {
    const parsed: Record<string, any> = {};
    headers?.split("\n").forEach(line => {
        const i = line.indexOf(':');
        const key = line.substr(0, i).trim().toLowerCase();
        const value = line.substring(i + 1).trim();

        if (key) {
            if (parsed[key] && ignoreDuplicateOf.indexOf(key) >= 0) {
                return;
            }
            if (key === 'set-cookie') {
                parsed[key] = (parsed[key] ? parsed[key] : []).concat([value]);
            } else {
                parsed[key] = parsed[key]
                    ? `${parsed[key]}, ${value}`
                    : value;
            }
        }
    })
    return parsed;
}

const getMultipartStrings = (content: string, boundary: string): string[] => {
    const boundaryRegex = new RegExp(`(\r\n)?--${boundary}(--)?\r\n`, "g");
    let offset = 0;
    const parts: string[] = [];
    let match = boundaryRegex.exec(content);
    while (match) {
        const part = content.substring(offset, match.index);
        part && parts.push(part);
        offset = match.index + match[0].length;
        match = boundaryRegex.exec(content);
    }
    offset < content.length && parts.push(content.substring(offset, content.length - 1));
    return parts;
}

const getMultiparts = (content: string, boundary: string): Multipart[] =>
    getMultipartStrings(content, boundary)
        .map((part, i) => {
            const match = /\r\n\r\n/.exec(part);
            return match ? <Multipart>{
                headers: parseHeaders(part.substr(0, match.index)),
                content: part.substring(match.index + match[0].length)
            } : <any>undefined;
        })
        .filter(Boolean);

const ETAG_KEY = "etag";
const OID_KEY = "red-dio-cer";
const DATE_KEY = "red-rec-dat";
const USERNAME_KEY = "red-usr-nam";
const MACHINE_NAME_KEY = "machine-name";
const CONTENT_LENGTH_KEY = "content-length";
const CONTENT_TYPE_KEY = "content-type";

export const useEndpoint = <TDefaultResponse = any, TOptions extends Options = Options>(
    axiosInstance: AxiosInstance,
    url: string | Ref<string>,
    baseHeaders: () => any,
    lastApiError: Ref<ApiError | undefined>,
    accessToken: AccessToken
) => {

    const busy = ref(false);
    const result = ref<any>();
    const headers = ref<any>();
    const status = ref<number>();
    const error = ref<ApiError>();

    const username = ref<string>();
    const etag = ref<string>();
    const date = ref<number>();
    const oid = ref<number>();

    const contentLength = () => parseFloat(headers.value?.[CONTENT_LENGTH_KEY] || "0");
    const machineName = () => headers.value?.[MACHINE_NAME_KEY];

    const reset = () => {
        busy.value = true;
        result.value = undefined;
        status.value = undefined;
        error.value = undefined;

        // username must not reset
        // etag must not reset
        // date must not reset
        // oid must not reset
    }

    const getUrl = (options: TOptions) => options.url
        ? unref(url) + options.url
        : unref(url);

    const setResponse = (axiosResponse: AxiosResponse) => {
        lastApiError.value = undefined;
        headers.value = axiosResponse.headers;
        status.value = axiosResponse.status;
        //console.log(axiosResponse.headers);
        if (axiosResponse.headers[USERNAME_KEY]) {
            username.value = axiosResponse.headers[USERNAME_KEY];
        }
        if (axiosResponse.headers[ETAG_KEY]) {
            etag.value = axiosResponse.headers[ETAG_KEY];
        }
        if (axiosResponse.headers[DATE_KEY]) {
            date.value = Date.parse(axiosResponse.headers[DATE_KEY]);
        }
        if (axiosResponse.headers[OID_KEY]) {
            oid.value = (parseInt(axiosResponse.headers[OID_KEY].substring(2)) - 67) / 3;
        }
    }

    const ensureEtag = (value: string | boolean | undefined) =>
        typeof value === "string"
            ? value
            : value === true
                ? etag.value
                : undefined;

    const getMatch = (key: string, value: string | boolean | undefined) => {
        const etag = ensureEtag(value);
        return etag ? {[key]: etag} : undefined;
    };

    const getHeadersFromOptions = (options: TOptions) => ({
        ...getMatch("If-Match", options.ifMatch),
        ...getMatch("If-None-Match", options.ifNoneMatch),
        ...options.headers,
    })

    const ensureCleaned = (data: any) => {
        if (isObject(data) && !(data instanceof FormData)) {
            data = {
                ...data
            }
            delete data.$$;
            delete data.$$scope;
        }
        return data;
    }

    const funcs = {
        requestConfig: (params: any, options: TOptions, send = false): AxiosRequestConfig => ({
            params: params,
            // @ts-ignore
            $$options: options,
            auth: options.auth,
            headers: {
                ...baseHeaders(),
                ...getHeadersFromOptions(options)
            }
        }),
        ensureResponse: <TResponse = any>(axiosResponse: AxiosResponse<TResponse>) => {
            let data = axiosResponse.data;
            const multipartMatch = /multipart\/(mixed); boundary="(.*)"/.exec(axiosResponse.headers[CONTENT_TYPE_KEY]);
            if (multipartMatch) {
                // @ts-ignore
                const requestOptions = axiosResponse.config.$$options;
                const parts = getMultiparts(data as any, multipartMatch[2]);

                data = isMultipartOptions(requestOptions.multiPart) && requestOptions.multiPart.detailed
                    ? parts as any
                    : parts.map(p => p.content);
            }
            result.value = data;
            setResponse(axiosResponse);
            return data;
        },
        ensureError: (axiosError: AxiosError) => {
            const apiError = ensureApiError(axiosError);
            status.value = axiosError?.response?.status;
            error.value = apiError;
            lastApiError.value = apiError;
            throw apiError;
        },
        ensureFinal: () => {
            busy.value = false;
        }
    }

    const {requestUserLogin} = useUserRelogin();
    const ensureAuthorized = <T>(config: AxiosRequestConfig, send: (c: AxiosRequestConfig) => Promise<AxiosResponse<T>>): Promise<AxiosResponse<T>> =>
        isAccessTokenValid(accessToken)
            ? send(config)
            : requestUserLogin()
                .then(() => {
                    config.headers = {
                        ...config?.headers,
                        ...createAuthorizationHeader(accessToken)
                    };
                    return send(config);
                });

    const send = <T = TDefaultResponse>(method: Send<T>, params?: any, options?: TOptions): Promise<T> => {
        reset();
        options = options || <TOptions>{};
        const url = getUrl(options);
        const config = funcs.requestConfig(params, options, false);
        return ensureAuthorized(config, c => method(url, c))
            .then(funcs.ensureResponse)
            .catch(funcs.ensureError)
            .finally(funcs.ensureFinal);
    }

    const sendData = <T = TDefaultResponse>(method: SendData<T>, data: any, params?: any, options?: TOptions): Promise<T> => {
        reset();
        options = options || <TOptions>{};
        data = ensureCleaned(data);
        const url = getUrl(options);
        const config = funcs.requestConfig(params, options, true);
        return ensureAuthorized(config, c => method(url, data, c))
            .then(funcs.ensureResponse)
            .catch(funcs.ensureError)
            .finally(funcs.ensureFinal);
    }

    const methods = {
        head: <TResponse = TDefaultResponse>(params?: any, options?: TOptions): Promise<TResponse> =>
            send<TResponse>(axiosInstance.head, params, options),
        get: <TResponse = TDefaultResponse>(params?: any, options?: TOptions): Promise<TResponse> =>
            send<TResponse>(axiosInstance.get, params, options),
        delete: (params: any, options?: TOptions): Promise<void> =>
            send<void>(axiosInstance.delete, params, options),
        put: <TResponse = TDefaultResponse, TData = any>(data: TData, params?: any, options?: TOptions): Promise<TResponse> =>
            sendData<TResponse>(axiosInstance.put, data, params, options),
        post: <TResponse = TDefaultResponse, TData = any>(data: TData, params?: any, options?: TOptions): Promise<TResponse> =>
            sendData<TResponse>(axiosInstance.post, data, params, options),
        patch: <TResponse = TDefaultResponse, TData = any>(data: TData, params?: any, options?: TOptions): Promise<TResponse> =>
            sendData<TResponse>(axiosInstance.patch, data, params, options),
    }

    return {
        funcs,

        busy,
        result,
        headers,
        status,
        error,

        username,
        etag,
        date,
        oid,

        contentLength,
        machineName,

        reset,
        getUrl,
        setResponse,
        getHeadersFromOptions,

        ...methods
    }

} 