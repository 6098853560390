﻿import type {MessageType} from "./useToasts";
import type {BusyOptions, PromisableEvent, ToastAction} from "../types";

import {isBoolean, isDefined, isFunction, isPromise, isString, isUndefined} from "./inspect";
import {resolveIconAlias, getBusyMessageIcon} from "./useIconAlias";
import {computed, readonly, ref} from "vue";
import {getDuration} from "./dateAndTime";
import {useToasts} from "./useToasts";

const DEFAULT_SUCCESS_TOAST_TITLE = "@@Aunoa.Operation.Successful";
const DEFAULT_FAILED_TOAST_TITLE = "@@Aunoa.Operation.Failed";

const ensureOptions = (options: BusyOptions) => ({
    delay: getDuration(options.delay, 25),
    minDuration: getDuration(options.minDuration, 250),
    successDuration: getDuration(options.successDuration, 500),
    failedDuration: getDuration(options.failedDuration, 750),
    successToast: options.successToast || false,
    successToastTitle: options.successToastTitle || DEFAULT_SUCCESS_TOAST_TITLE,
    failedToast: options.failedToast || isDefined(options.failedToastActions) || false,
    failedToastTitle: options.failedToastTitle || DEFAULT_FAILED_TOAST_TITLE,
    failedToastActions: options.failedToastActions,
    toastGroup: options.toastGroup,
    dataKind: options.dataKind,
    keepMenuOpen: options.keepMenuOpen
});

const ensureEnabled = (value: any, result: any): boolean =>
    (isFunction(value) && value(result)) || (!isFunction(value) && value)

const ensureTitle = (value: any, result: any): string =>
    isFunction(value) ? value(result) : value

const ensureToastActions = (value: any, result: any): ToastAction[] =>
    isFunction(value) ? value(result) : value;


//https://stackoverflow.com/questions/64336083/unexpected-unhandledrejection-event-for-promise-which-rejection-does-get-handled
// window.addEventListener("unhandledrejection", event => {
//     //event.preventDefault();
//     // You can use `event.reason` here for the rejection reason, and
//     // `event.promise` for the promise that was rejected
//     //console.log(`Suppressed the rejection '${event.reason.message}'`);
//     console.log("unhandledrejection", event);
// });

const httpStatusMessageTypeDict: Record<number, MessageType> = {
    304: "info",
    409: "warning"
}

const STATUS_ERROR = "@@Aunoa.Error.Http.Status";
const httpStatusTranslationDict: Record<number, string> = {
    304: STATUS_ERROR + ".E304",
    404: STATUS_ERROR + ".E404",
    409: STATUS_ERROR + ".E409"
}

const httpStatusMethodTranslationDict: Record<string, Record<number, string>> =
    {
        "put": {
            409: STATUS_ERROR + ".E409Put"
        },
        "post": {
            409: STATUS_ERROR + ".E409Post"
        }
    }


const getHttpStatusTranslation = (httpMethod: string, httpStatus: number) =>
    (httpStatusMethodTranslationDict[httpMethod] || httpStatusTranslationDict)[httpStatus];


export const useBusy = () => {

    const {addSuccessToast, addToast} = useToasts();
    const busyIcon = ref<string>();
    //watch(busyIcon, i=> console.log("ICON", i));
    const isBusy = computed(() => !!busyIcon.value);

    const start = <T = any>(promise: Promise<T>, options?: BusyOptions) =>
        new Promise<T>((resolve, reject) => {

            const o = ensureOptions(options || {});
            const minDuration = new Promise<void>(r => setTimeout(r, o.minDuration));
            const failedDuration = () => new Promise<void>(r => setTimeout(r, o.failedDuration));
            const busyIconDelay = setTimeout(() => busyIcon.value = resolveIconAlias("busy"), o.delay);

            const onSuccess = async (resultValue: any) => {

                const showSuccessToast = (content: string | undefined) =>
                    addSuccessToast({
                        content,
                        title: ensureTitle(o.successToastTitle, resultValue),
                        group: o.toastGroup
                    });

                if (isFunction(o.successToast)) {
                    const result = o.successToast(resultValue);
                    if (isString(result)) {
                        showSuccessToast(result)
                    } else if (isBoolean(result) && result) {
                        showSuccessToast(undefined);
                    }
                } else if (isString(o.successToast)) {
                    showSuccessToast(o.successToast);
                } else if (isBoolean(o.successToast)) {
                    if (o.successToast) {
                        showSuccessToast(undefined);
                    }
                }

                await minDuration;
                clearInterval(busyIconDelay);
                if (o.successDuration > 0) {
                    busyIcon.value = getBusyMessageIcon("success");
                    await new Promise<void>(r => setTimeout(r, o.successDuration));
                }
                busyIcon.value = undefined;
                resolve(resultValue);
            }

            const onFailed = async (error: any) => {

                const messageType = error.type || httpStatusMessageTypeDict[error?.httpStatus] || "error";

                const showFailedToast = (content: string | undefined) => {
                    if (isUndefined(content)) {
                        if (error.content) {
                            content = error.content;
                        } else if (error?.httpStatus && o.dataKind) {
                            const translation = getHttpStatusTranslation(error.httpMethod, error.httpStatus);
                            if (translation) {
                                error = {
                                    ...error,
                                    message: translation + o.dataKind
                                }
                            }
                        }
                    }

                    addToast(messageType, {
                        content: content || error,
                        title: error.title || ensureTitle(o.failedToastTitle, error),
                        actions: ensureToastActions(o.failedToastActions, error),
                        group: o.toastGroup || error.group
                    });
                };

                if (isFunction(o.failedToast)) {
                    const result = o.failedToast(error);
                    if (isString(result)) {
                        showFailedToast(result)
                    } else if (isBoolean(result) && result) {
                        showFailedToast(undefined);
                    }
                } else if (isString(o.failedToast)) {
                    showFailedToast(o.failedToast);
                } else if (isBoolean(o.failedToast)) {
                    if (o.failedToast) {
                        showFailedToast(undefined);
                    }
                }

                await minDuration;
                clearInterval(busyIconDelay);
                if (o.failedDuration > 0) {
                    busyIcon.value = getBusyMessageIcon(messageType);
                    await new Promise<void>(r => setTimeout(r, o.failedDuration));
                }
                busyIcon.value = undefined;
                reject(error);
            }

            promise
                .then(onSuccess)
                .catch(onFailed);
        })

    const tryStart = <T = any>(promise: Promise<T>, options?: BusyOptions): Promise<T> =>
        isPromise(promise)
            ? start(promise, options)
            : Promise.resolve<T>(<any>undefined)

    const emitPromisableEvent = (event: PromisableEvent, emit: any) => {
        const emitPromise = new Promise<any>(resolve => {
            event.setPromise = (promise, options) => {
                if (options?.keepMenuOpen) {
                    event.target = undefined;
                }
                const busyPromise = tryStart(promise, options);
                resolve(busyPromise);
                return busyPromise;
            }
            setTimeout(() => resolve(false));
        })
        emit("click", event);
        return emitPromise;
    }

    return {
        busyIcon: readonly(busyIcon),
        isBusy,
        start,
        tryStart,
        emitPromisableEvent
    }
}