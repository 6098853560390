import type {PropType} from "vue";
import type {Entities, Forms} from "../types";
import type {FormMode} from "../implementations/forms/useFormMode";
import type {LookupFactories} from "../implementations/lookup/useLookup";

export type SubmitFunc = (entity: any, option?: string) => Promise<any>;

export const formPropsCoreOptions = {
    title: {
        type: String
    },
    autocomplete: {
        type: Boolean,
        default: false
    },
    entityModel: {
        type: Array as PropType<Entities.Model.Property[]>,
        default: undefined
    },
    formModel: {
        type: Object as PropType<Forms.Model.Form>,
        default: undefined
    },
    lookupFactories: {
        type: Object as PropType<LookupFactories>,
        default: undefined
    },
    readOnly: {
        type: Boolean,
        default: false
    },
    mode: {
        type: String as PropType<FormMode>,
        default: "default"
    }
};// as ComponentObjectPropsOptions<FormProps>;

export const formPropsOptions = {
    ...formPropsCoreOptions,
    entity: {
        type: Object,
        default: undefined
    },
    onSubmit: {
        type: Function as PropType<SubmitFunc>,
        default: undefined,
    }
};// as ComponentObjectPropsOptions<FormProps>;
