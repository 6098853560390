﻿import {Entities, PropertyPath, Tables} from "../types";
import {getPropertyName} from "../utils/reflectionLike";
import {createPropertyPath} from "../utils/properties";

export interface EntityModelOptions {
}

interface Property {
    options: any;

    createModel(): Entities.Model.Property;
}

const stringProperty = <TEntity = any>(name: string): Property => {

    let required: boolean | undefined;
    let unique: boolean | undefined;
    let minLength: number | undefined;
    let maxLength: number | undefined;
    let defaultValue: string | undefined;
    let resetOnPropertyChange: string;

    const options: Entities.Builder.String<TEntity> = {
        Required: (value = true) => set(() => required = value),
        Unique: (value = true) => set(() => unique = value),
        Length: value => options.MinLength(value).MaxLength(value),
        MinLength: value => set(() => minLength = value),
        MaxLength: value => set(() => maxLength = value),
        DefaultValue: value => set(() => defaultValue = value),
        ResetOnPropertyChange: value => {
            if (value) {
                const builder = createPropertyPath();
                value(builder);
                resetOnPropertyChange = builder.Build();
            }
            return options;
        }
    }

    const set = (action: () => void) => {
        action();
        return options;
    }

    return {
        options,
        createModel: (): Entities.Model.Property => ({
            type: "String",
            name,
            required,
            unique,
            minLength,
            maxLength,
            defaultValue,
            resetOnPropertyChange
        })
    }

};

const numberProperty = <TEntity = any>(name: string): Property => {

    let required: boolean | undefined = undefined;
    let unique: boolean | undefined = undefined;
    let nullable: boolean | undefined;
    let min: number | undefined;
    let max: number | undefined;
    let defaultValue: number | undefined;
    let resetOnPropertyChange: string;

    const options: Entities.Builder.Number<TEntity> = {
        Required: (value = true) => set(() => required = value),
        Unique: (value = true) => set(() => unique = value),
        Nullable: (value = true) => set(() => nullable = value),
        Min: value => set(() => min = value),
        Max: value => set(() => max = value),
        DefaultValue: value => set(() => defaultValue = value),
        ResetOnPropertyChange: value => {
            if (value) {
                const builder = createPropertyPath();
                value(builder);
                resetOnPropertyChange = builder.Build();
            }
            return options;
        }
    }

    const set = (action: () => void) => {
        action();
        return options;
    }

    return {
        options,
        createModel: (): Entities.Model.Property => ({
            type: "Number",
            name,
            nullable,
            required,
            unique,
            min,
            max,
            defaultValue,
            resetOnPropertyChange
        })
    }
};

const booleanProperty = <TEntity = any>(name: string): Property => {

    let required: boolean | undefined;
    let unique: boolean | undefined;
    let nullable: boolean | undefined;
    let defaultValue: boolean | undefined;

    const options: Entities.Builder.Boolean<TEntity> = {
        Required: (value = true) => set(() => required = value),
        Unique: (value = true) => set(() => unique = value),
        Nullable: (value = true) => set(() => nullable = value),
        DefaultValue: value => set(() => defaultValue = value),
    }

    const set = (action: () => void) => {
        action();
        return options;
    }

    return {
        options,
        createModel: (): Entities.Model.Property => ({
            type: "Boolean",
            name,
            nullable,
            required,
            unique,
            defaultValue
        })
    }

};


const objectProperty = <TEntity = any>(name: string): Property => {

    let required: boolean | undefined;
    let unique: boolean | undefined;
    let nullable: boolean | undefined;

    const options: Entities.Builder.ChildObject<TEntity> = {
        Required: (value = true) => set(() => required = value),
        Unique: (value = true) => set(() => unique = value),
        Nullable: (value = true) => set(() => nullable = value),
    }

    const set = (action: () => void) => {
        action();
        return options;
    }

    return {
        options,
        createModel: (): Entities.Model.Property => ({
            type: "Object",
            name,
            nullable,
            required,
            unique,
        })
    }

};


const arrayProperty = <TEntity = any>(name: string): Property => {

    let required: boolean | undefined;
    let unique: boolean | undefined;
    let resetOnPropertyChange: string;

    const options: Entities.Builder.Array<TEntity> = {
        Required: (value = true) => set(() => required = value),
        Unique: (value = true) => set(() => unique = value),
        ResetOnPropertyChange: value => {
            if (value) {
                const builder = createPropertyPath();
                value(builder);
                resetOnPropertyChange = builder.Build();
            }
            return options;
        }
    }

    const set = (action: () => void) => {
        action();
        return options;
    }

    return {
        options,
        createModel: (): Entities.Model.Property => ({
            type: "Array",
            name,
            required,
            unique,
            resetOnPropertyChange
        })
    }

};

export const createEntityModel = <TEntity = any>(options?: EntityModelOptions): Entities.Builder.Object<TEntity> => {

    const properties: Property[] = [];

    const object: Entities.Builder.Object<TEntity> = {
        String: (getter, options) => property(getter, stringProperty, options),
        Number: (getter, options) => property(getter, numberProperty, options),
        Boolean: (getter, options) => property(getter, booleanProperty, options),
        Object: (getter, options) => property(getter, objectProperty, options),
        Array: (getter, options) => property(getter, arrayProperty, options),
        Build: () => properties.map(p => p.createModel())
    }

    const property = (getter: (o: TEntity) => any, func: (p: string) => any, options?: (n: any) => void) => {
        const propertyName = getPropertyName<TEntity>(getter) as string;
        const property = func(propertyName);
        properties.push(property)
        if (options) {
            options(property.options);
        }
        return object
    }

    return object;
}
