<template>
    <form
        ref="dropzoneElement"
        class="dropzone"
    />
</template>

<script lang="ts">

import {computed, defineComponent, ref, watch} from "vue";
import Dropzone, {DropzoneFile, DropzoneOptions} from "dropzone";
import {useAunoaI18n} from "../utils/useAunoaI18n";
import {isArray} from "../utils/inspect";
import {useUrlTools} from "../utils/useUrlTools";

const de = {
    'dictDefaultMessage': 'Legen Sie Dateien hier ab um Sie hochzuladen',
    'dictFallbackMessage': 'Ihr Browser unterstützt Drag&Drop Dateiuploads nicht',
    'dictFallbackText': 'Benutzen Sie das Formular um Ihre Dateien hochzuladen',
    'dictFileTooBig': 'Die Datei ist zu groß. Die maximale Dateigröße beträgt {{maxFileSize}}MB',
    'dictInvalidFileType': 'Eine Datei dieses Typs kann nicht hochgeladen werden',
    'dictResponseError': 'Der Server hat ihre Anfrage mit Status {{statusCode}} abgelehnt',
    'dictCancelUpload': 'Hochladen abbrechen',
    'dictCancelUploadConfirmation': null,
    'dictRemoveFile': 'Datei entfernen',
    'dictMaxFilesExceeded': 'Sie können keine weiteren Dateien mehr hochladen'
}

export default defineComponent({
    name: "AunoaDropzone",
    props: {
        url: {
            type: String,
            default: "/target"
        },
        acceptedFiles: {
            type: String
        },
        method: {
            type: String,
            default: "post"
        },
        timeout: {
            type: Number,
            default: null
        },
        parallelUploads: {
            type: Number,
            default: 2
        },
        uploadMultiple: {
            type: Boolean,
            default: false
        },
        maxFiles: {
            type: Number,
            default: null
        }
    },
    emits: ["select"],
    setup: (props, {emit}) => {
        const dropzoneElement = ref<HTMLFormElement>();

        const {locale} = useAunoaI18n()
        
        const {ensureUrl, createAuthorizationHeader} = useUrlTools()

        const mergeOptions = (options: DropzoneOptions) => {
            options.headers = createAuthorizationHeader(props.url);
            options.url = ensureUrl(props.url);
            options.acceptedFiles = props.acceptedFiles;
            options.method = props.method;
            options.timeout = props.timeout;
            options.parallelUploads = props.parallelUploads;
            options.uploadMultiple = props.uploadMultiple;
            options.maxFiles = props.maxFiles;
            return options;
        }

        function init(this: Dropzone) {

            let lastFile: DropzoneFile|undefined;

            this.on("addedfile", (file:DropzoneFile) => {
                mergeOptions(this.options);
                if (this.options.maxFiles===1 && lastFile !== undefined) {
                    this.removeFile(lastFile);
                    lastFile = undefined;
                    emit("select");
                }
            });
            
            this.on("processing", (file:DropzoneFile) => {
            });

            if (this.options.uploadMultiple) {
                this.on("successmultiple", (files: DropzoneFile[], result: any) => {
                    lastFile = files[files.length - 1];
                    emit("select", files, result);
                });

            } else {
                this.on("success", (file: DropzoneFile, result: any) => {
                    lastFile = file;
                    if (isArray(result)) {
                        emit("select", file, result[0]);
                    } else {
                        emit("select", file, result);
                    }
                });
            }

        }

        const dropzoneOptions = computed<DropzoneOptions>(() => ({
            ...mergeOptions({}),
            dictDefaultMessage: "Drop files here to upload " + locale.value,
            init
        }));

        let dropzone: Dropzone | undefined;
        watch([dropzoneElement, dropzoneOptions], ([element, options], [oldElement]) => {
            if (oldElement) {
                if (dropzone) {
                    dropzone.destroy();
                    dropzone = undefined;
                }
                oldElement.innerHTML = "";
            }
            if (element) {
                // console.log(options);
                dropzone = new Dropzone(element, options);
            }
        });

        return {
            dropzoneElement,
            dropzoneOptions
        }

    }
})

</script>
