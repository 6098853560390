﻿<template>
    <div v-bind="$attrs">

        <aunoa-nav class="p-0 small">
            <aunoa-nav-item class="text-danger fw-bold" @click="onClick">
                <template v-slot:link>
                    <i class="far"
                       :class="showStackFrames?'fa-chevron-double-up':'fa-chevron-double-down'"
                    />
                    <span v-text="message" />
                </template>
            </aunoa-nav-item>
            <aunoa-nav-item
                item-class="ms-auto"
                icon="far fa-times"
                @click="onClose" />
        </aunoa-nav>

        <table class="table table-borderless table-sm small bg-body" v-if="showStackFrames">
            <tbody>
            <tr v-for="frame in stackFrames">
                <td class="text-end ps-3">
                    <div v-if="frame.line && frame.column">
                        <span v-text="frame.line" />
                        <span>:</span>
                        <span v-text="frame.column" />
                    </div>
                </td>
                <td :class="frame.line && frame.column?undefined:'text-muted'">
                    <span v-text="frame.classType" />
                    <span>.</span>
                    <strong v-text="frame.method?.name" />
                </td>
                
            </tr>
            </tbody>
        </table>


        <pre v-text="JSON.stringify(error, null, 4)" v-if="0" />

    </div>
</template>

<script lang="ts">

import {defineComponent, computed, toRefs, ref, watch} from "vue";
import AunoaNav from "./nav/AunoaNav.vue";
import AunoaNavItem from "./nav/AunoaNavItem.vue";


export default defineComponent({
    name: "AunoaErrorDisplay",
    inheritAttrs: false,
    components: {
        AunoaNav,
        AunoaNavItem,
    },
    props: {
        error: {
            type: Object,
            default: undefined
        }
    },
    emit: ["close"],
    setup(props, {emit}) {

        const {error: _error} = toRefs(props);
        const _message = ref();
        const stackFrames = ref([]);
        const showStackFrames = ref(false);

        watch(_error, error => {
            if (error) {
                error = error["tnt.exception"] || error;
                let message = error.message || error;
                message = message.value || message;
                _message.value = message;
                stackFrames.value = error.stackFrames;
            } else {
                _message.value = undefined;
                stackFrames.value = [];
            }

        }, {immediate: true});


        const onClose = () => emit("close");
        const onClick = () => showStackFrames.value=!showStackFrames.value;

        return {
            message: _message,
            stackFrames,
            showStackFrames,
            onClose,
            onClick
        }
    }
});

</script>

